export const program = {
  getAllPrograms: {
    url: "admin/program/getAll",
    method: "GET",
    params: null,
  },
  getProgramForVendor: {
    url: "vendor/program/getAll",
    method: "GET",
    params: null,
  },
  uploadMultimedia: {
    url: "admin/program/uploadImage",
    method: "POST",
    data: null,
  },
  createProgram: {
    url: "vendor/program/create",
    method: "POST",
    data: null,
  },
  createProgramAdmin: {
    url: "admin/program/create",
    method: "POST",
    data: null,
  },
  updateProgramAdmin: {
    url: "admin/program/updateForAdmin",
    method: "POST",
    data: null,
  },
  updateProgram: {
    url: "vendor/program/updateForVendor",
    method: "POST",
    data: null,
  },
  getSingleProgram: {
    url: "admin/program/getOne",
    method: "GET",
    params: null,
  },
  updateProgramDates: {
    url: "vendor/program/updateDates",
    method: "POST",
    data: null,
  },
  getAvailableVendor: {
    url: "vendor/getAllWithTribe",
    method: "GET",
  },
  getAllFilteredForDash: {
    url: "program/getAllFilteredForDash",
    method: "GET",
    params: null,
  },
  getAllVendorsAndTribes: {
    url: "vendor/getAllVendorsAndTribes",
    method: "GET",
    params: null,
  },
  disableProgram:{
    url: "admin/program/disableProgram",
    method: "POST",
    data:null,
  }
};
