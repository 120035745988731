<template>
  <Layout>
    <div class="row" v-if="program">
      <div class="col-lg-12">
        <div class="card">
          <div>
            <div v-if="userType === 'admin'">
              <b-button
                v-if="program.is_approved"
                class="ml-auto mt-3"
                style="margin-right: 18px; height: 28px; padding-top: 3px"
                type="primary"
                @click="toggleProgramStatus(program._id, program.is_disabled)"
              >
                {{
                  program.is_disabled
                    ? `${changingStatus ? "Activating..." : "Activate"}`
                    : `${changingStatus ? "Deactivating..." : "Deactivate"}`
                }}</b-button
              >

              <b-button
                v-if="!program.is_approved"
                class="ml-auto mt-3"
                style="margin-right: 18px; height: 28px; padding-top: 3px"
                type="primary"
                @click="approveProgram"
                ><i class="ri-settings-5-line mr-2"></i> Approve</b-button
              >
              <b-button
                v-else
                class="ml-auto mt-3"
                style="margin-right: 18px; height: 28px; padding-top: 3px"
                type="primary"
                @click="editProgram"
                ><i class="ri-settings-5-line mr-2"></i> Edit</b-button
              >
            </div>
            <div v-else>
              <b-button
                class="ml-auto mt-3"
                style="margin-right: 18px; height: 28px; padding-top: 3px"
                type="primary"
                @click="editProgram"
                ><i class="ri-settings-5-line mr-2"></i> Edit</b-button
              >
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5 mt-1">
                <div class="m-1">
                  <img
                    :src="program.banner"
                    alt="Banner"
                    class="program-banner"
                    srcset=""
                  />
                </div>
              </div>
              <div class="col-xl-7">
                <div class="mt-4 mt-xl-3">
                  <h5 class="mt-1 mb-3">{{ program.name }}</h5>

                  <p class="mt-3">
                    {{ program.description }}
                  </p>
                  <hr class="my-4" />

                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <h5 class="font-size-14">Specification</h5>
                        <ul class="list-unstyled product-desc-list">
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            Minimum {{ program.more_details.min_people }} people
                          </li>
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            Maximum {{ program.more_details.max_people }} people
                          </li>
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            Price
                            ( {{ minPrice }} -
                            {{ maxPrice }} )
                          </li>
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            Maximum {{ program.more_details.max_people }} people
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>

          <div class="m-4">
            <div class="product-desc">
              <b-tabs
                class="nav-tabs-custom"
                content-class="border border-top-0 p-4"
              >
                <b-tab title="More">
                  <table>
                    <tr>
                      <td style="width: 138px">Cancellation Policy</td>
                      <td>
                        {{ program.cancellation_policy }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 138px">Included</td>
                      <td>
                        {{ program.more_details.included }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 138px">Excluded</td>
                      <td>
                        {{ program.more_details.excluded }}
                      </td>
                    </tr>
                  </table>
                </b-tab>
                <b-tab title="Details">
                  <div class="row">
                    <div
                      class="col-12"
                      v-for="details in program.details"
                      :key="details._id"
                    >
                      <div class="card m-1 p-4">
                        <div class="d-flex flex-wrap">
                          <img
                            style="width: 84px"
                            :src="details.image"
                            alt="Image"
                            srcset=""
                            class="mr-4"
                          />
                          <div class="mt-3">
                            <h6>{{ details.title }}</h6>
                            <p>{{ details.description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { program } from "@/config/api/programs";
import { programMethods } from "@/state/helpers";
import capitalizeFirstLetter from "@/utils/capitalize.js";
export default {
  data() {
    return {
      program: null,
      timeslots: {},
      updatingSlots: false,
      changingStatus: false,
      timeSlotSchema: [
        {
          type: "group",
          repeatable: true,
          class: "available-time-slot",
          name: "slots",
          addLabel: "+ Add more",
          children: [
            {
              type: "date",
              name: "date",
              validation: "required",
            },
          ],
        },
      ],
    };
  },
  watch: {
    program(val) {
      this.timeslots.slots = [];
      val.available_dates.forEach((ele) => {
        this.timeslots.slots.push({
          date: ele,
        });
      });
    },
  },
  components: {
    Layout,
  },
  computed: {
    id() {
      return this.$route.query.id;
    },

    userType() {
      return this.$store.getters["auth/userType"];
    },
    minPrice() {
      if (this.program) {
        const temp = this.program.packages.map((item) => item.total_price);
        return Math.min(...temp);
      }
      return 0;
    },
    maxPrice() {
      if (this.program) {
        const temp = this.program.packages.map((item) => item.total_price);
        return Math.max(...temp);
      }
      return 0;
    },
  },
  created() {
    this.getProgramDetails();
  },
  methods: {
    getProgramDetails() {
      const api = program.getSingleProgram;
      api.params = {
        id: this.id,
      };
      this.generateAPI(api).then((res) => {
        this.program = res.data.program;
      });
    },
    async submitHandler(data) {
      const api = program.updateProgramDates;
      api.data = {
        programId: this.$route.query.id,
        type: "admin",
        available_dates: data.slots.map((item) => {
          return item.date;
        }),
      };
      this.updatingSlots = true;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Program date slot updated successfully.", {
            title: "Program update",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err.data);
          this.$bvToast.toast("Program slot update failed!.", {
            title: "Program update",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.updatingSlots = false;
        });
    },
    editProgram() {
      this.$router.push({ name: "editProgram" });
      this.selectProgram(this.program);
    },
    approveProgram() {
      this.$router.push({ name: "approveProgram" });
      this.selectProgram(this.program);
    },
    ...programMethods,
    toggleProgramStatus(id, is_disabled) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to ${is_disabled ? "enable" : "disable"}?`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((result) => {
          if (result) {
            const data = {
              type: capitalizeFirstLetter(this.userType),
              programId: id,
              is_disabled: !is_disabled,
            };
            const api = program.disableProgram;
            api.data = data;
            this.changingStatus = true;
            this.generateAPI(api)
              .then((res) => {
                console.log(res.data);
                this.program.is_disabled = !this.program.is_disabled;
              })
              .catch(() => {
                this.$bvToast.toast("Program status updated.", {
                  title: "Program status",
                  variant: "success",
                  solid: true,
                });
              })
              .finally(() => {
                this.$bvToast.toast("Program status update failed!.", {
                  title: "Program status",
                  variant: "danger",
                  solid: true,
                });
                this.changingStatus = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
    },
  },
};
</script>

<style scoped>
.program-banner {
  width: 100%;
  max-width: 380px;
  display: block;
  margin: auto;
  border-radius: 12px;
}
</style>
<style>
.available-time-slot .formulate-input-grouping {
  display: flex;
  flex-wrap: wrap;
}

.available-time-slot .formulate-input-group-repeatable {
  border-bottom: unset !important;
}
.available-time-slot .formulate-input-group-repeatable-remove {
  top: 26px !important;
}
</style>
